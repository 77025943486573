<template>
    <div>
        <div class="alert text-center" :class="alertClass" role="alert">
            {{messageText}}
        </div>
        <form>  
            <div class="input-group mt-3">    
                <select class="form-control form-control-lg" v-model="selectedLevel1">
                    <option selected disabled value="">Choose...</option>
                    <option v-for="(item, index) in assets" :key="index" v-bind:value="index">{{item.name}}</option>
                </select>
                <div class="input-group-append">
                    <span class="input-group-text" @click.prevent="ClearSelection(1)"><i class="fas fa-window-close text-danger"></i></span>
                </div>
            </div>  
            <div class="input-group mt-3">
                <select class="form-control form-control-lg" :disabled="level2Tree.length == 0" v-model="selectedLevel2" >
                    <option selected disabled value="">Choose...</option>
                    <option v-for="(item, index) in level2Tree" :key="index" v-bind:value="index">{{item.name}}</option>
                </select>
                <div class="input-group-append">
                    <span class="input-group-text" @click.prevent="ClearSelection(2)"><i class="fas fa-window-close text-danger"></i></span>
                </div>
            </div> 
            <div class="input-group mt-3">
                <select class="form-control form-control-lg" :disabled="level3Tree.length == 0" v-model="selectedLevel3" >
                    <option selected disabled value="">Choose...</option>
                    <option v-for="(item, index) in level3Tree" :key="index" v-bind:value="index">{{item.name}}</option>
                </select>
                <div class="input-group-append">
                    <span class="input-group-text" @click.prevent="ClearSelection(3)"><i class="fas fa-window-close text-danger"></i></span>
                </div>
            </div> 
            <div class="input-group mt-3">
                <select class="form-control form-control-lg" :disabled="level4Tree.length == 0" v-model="selectedLevel4" >
                    <option selected disabled value="">Choose...</option>
                    <option v-for="(item, index) in level4Tree" :key="index" v-bind:value="index">{{item.name}}</option>
                </select>
                <div class="input-group-append">
                    <span class="input-group-text" @click.prevent="ClearSelection(4)"><i class="fas fa-window-close text-danger"></i></span>
                </div>
            </div> 

            <div class="input-group mt-3">
                <select class="form-control form-control-lg" :disabled="level5Tree.length == 0" v-model="selectedLevel5" >
                    <option selected disabled value="">Choose...</option>
                    <option v-for="(item, index) in level5Tree" :key="index" v-bind:value="index">{{item.name}}</option>
                </select>
                <div class="input-group-append">
                    <span class="input-group-text" @click.prevent="ClearSelection(5)"><i class="fas fa-window-close text-danger"></i></span>
                </div>
            </div> 
                    
        </form>
    </div>
</template>


<script>
export default {
  props:{
      assets:{
          type:Array,
          required: true
      },
      selectedLevels:{
          type: Array,
          required: true
      },
      initialMessage :{
          type : String
      }
  },
  name: "Asset",
  data(){
      return{

          selectedLevel1: this.selectedLevels[0] || "",
          selectedLevel2: this.selectedLevels[1] || "",
          selectedLevel3: this.selectedLevels[2] || "",
          selectedLevel4: this.selectedLevels[3] || "",
          selectedLevel5: this.selectedLevels[4] || "" 

      }
  },
  methods:{
      
    ClearSelection : function(idx){
        console.log(idx);
        switch(idx){
            case 1: this.selectedLevel1 = ""; break;
            case 2: this.selectedLevel2 = ""; break;
            case 3: this.selectedLevel3 = ""; break;
            case 4: this.selectedLevel4 = ""; break;
            case 5: this.selectedLevel5 = ""; break;
        }
    }
  },
  computed:{  

      alertClass : function(){
          if(this.assets.length === 0)
            return 'alert-warning';
        else 
            return this.isAssetValid ? 'alert-success' : 'alert-danger';
      },
      level2Tree : function(){

        if(this.selectedLevel1 !== "" && this.selectedLevel1 >= 0)
            return this.assets[this.selectedLevel1].children;
        else
            return [];
      },
      level3Tree : function(){

        if(this.selectedLevel2 !== "" && this.selectedLevel2 >= 0)
            return this.level2Tree[this.selectedLevel2].children;
        else
            return [];
      },
      level4Tree : function(){

        if(this.selectedLevel3 !== "" && this.selectedLevel3 >= 0)
            return this.level3Tree[this.selectedLevel3].children;
        else 
            return [];
      },
      level5Tree : function(){
        
        if(this.selectedLevel4 !== "" && this.selectedLevel4 >= 0)
            return this.level4Tree[this.selectedLevel4].children;
        else 
            return [];
      },
      selectedAsset : function(){

        let node = null;
        if(this.selectedLevel1 !== "" && this.selectedLevel1 >= 0)
            node = this.assets[this.selectedLevel1];//node = Buildings, Grounds, Amenities
        if(this.selectedLevel2 !== "" && this.selectedLevel2 >= 0)
            node = node.children[this.selectedLevel2]; //node = Air Conditioning Systems
        if(this.selectedLevel3 !== "" && this.selectedLevel3 >= 0)
            node = node.children[this.selectedLevel3];  //node = Door 4 Office Split Systems X 3
        if(this.selectedLevel4 !== "" && this.selectedLevel4 >= 0)
            node = node.children[this.selectedLevel4];  //node = Door 4 Office Split Systems X 3
        if(this.selectedLevel5 !== "" && this.selectedLevel5 >= 0)
            node = node.children[this.selectedLevel5];  //node = Door 4 Office Split Systems X 3

        return node;
        
      },
      isAssetValid : function(){

        return this.selectedAsset !== null && this.selectedAsset.valid == true;

      },

      messageText : function(){

          if(this.assets.length==0){
              return "Loading Assets....";
          }
          return this.isAssetValid ? this.selectedAsset.pname : "Select a valid asset";
      }
  },
  watch:{
      selectedLevel1 : function(){
          this.selectedLevel2 = "";
      },
      selectedLevel2 : function(){
          this.selectedLevel3 = "";
      },
      selectedLevel3 : function(){
          this.selectedLevel4 = "";
      },
      selectedLevel4 : function(){
          this.selectedLevel5 = "";
      },
      selectedAsset : function(val){
          var asset = {
            asset: val,
            valid: this.isAssetValid,
            selectedLevels: [this.selectedLevel1, this.selectedLevel2, this.selectedLevel3, this.selectedLevel4, this.selectedLevel5]
            };
          this.$emit('AssetSelectionChanged', asset);
      }

  }
 
};
</script>

<style scoped>
   .input-group-text {
       height:100%;
       border-radius: 0;
       cursor: pointer;
       font-size:1.5em;
   }

</style>